import { CallbackFunc } from "@geome/react-next/lib/context/userInteraction/types"
import { Filter, GooglePlaceLiteral, SelectionIntent } from "@geome/types"
import { ShellRetailLocation } from "../../types"
import { sendAnalytics } from "./sendAnalytics"

export const sendLocationSelect = (location: ShellRetailLocation): void => {
  const { name, id } = location

  sendAnalytics({
    action: "stationclick",
    text: JSON.stringify({ id, name }),
  })
}

export const sendGooglePlaceSelect = (place: GooglePlaceLiteral): void => {
  sendAnalytics({
    action: "search",
    text: { country: place.country, city: place.city },
  })
}

type CompletionSelectArgs = {
  completion: SelectionIntent
  selectedPlace: GooglePlaceLiteral | null
  selectedLocation: ShellRetailLocation | null
}

export const onCompletionSelect: CallbackFunc<CompletionSelectArgs, boolean> = (_topic, data) => {
  const { completion: selectionIntent, selectedPlace, selectedLocation } = data
  if (
    selectionIntent.type === "location" &&
    selectedLocation &&
    selectedLocation.id === selectionIntent.id
  ) {
    sendLocationSelect(selectedLocation)
    return true
  }
  if (
    selectionIntent.type === "google_place" &&
    selectedPlace &&
    selectionIntent.place_id === selectedPlace.place_id
  ) {
    sendGooglePlaceSelect(selectedPlace)
    return true
  }
  return false
}

type DirectionsCompletionSelectArgs = {
  selectionIntents: (SelectionIntent | null)[]
}

export const onDirectionsCompletionSelect: CallbackFunc<DirectionsCompletionSelectArgs, boolean> = (
  _topic,
  data
) => {
  const { selectionIntents } = data
  const nonNullselectionIntents = selectionIntents.filter((si) => si !== null)
  if (nonNullselectionIntents.length > 0) {
    sendAnalytics({
      action: "directionsearch",
      text: { description: nonNullselectionIntents.map((si) => si?.description).join(", ") },
    })
    return true
  }
  return false
}

export const onFilterToggle: CallbackFunc<Filter> = (_topic, filter) => {
  sendAnalytics({
    action: "filter",
    text: JSON.stringify({ name: filter.filterName, group: filter.groupName }),
  })
}

type GeolocationClickArgs = {
  selectedGooglePlace: GooglePlaceLiteral | null
}

export const onGeolocationClick: CallbackFunc<GeolocationClickArgs, boolean> = (
  _topic,
  { selectedGooglePlace }
) => {
  if (selectedGooglePlace) {
    sendAnalytics({
      action: "usemylocation",
      text: { city: selectedGooglePlace.city, country: selectedGooglePlace.country },
    })
    return true
  }
  return false
}
