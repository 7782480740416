import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React, { ReactElement } from "react"

export const RouteOptionsIcon = (): ReactElement => {
  const colour = useCSSCustomProperty("--shell-grey-colour", "#404040")
  return (
    <svg aria-hidden="true" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        d="M6.41667 16V12C6.41667 11.36 6.31296 10.8333 6.10556 10.42C5.89815 10.0067 5.58056 9.58 5.15278 9.14L5.98889 8.28C6.15741 8.42667 6.33565 8.62667 6.52361 8.88C6.71157 9.13333 6.87037 9.36667 7 9.58C7.22037 9.23333 7.4375 8.93333 7.65139 8.68C7.86528 8.42667 8.06944 8.21333 8.26389 8.04C9.01574 7.41333 9.55694 6.65667 9.8875 5.77C10.2181 4.88333 10.3444 3.72 10.2667 2.28L8.51667 4.08L7.7 3.24L10.85 0L14 3.24L13.1833 4.08L11.4333 2.28C11.4981 3.96 11.3394 5.28333 10.9569 6.25C10.5745 7.21667 9.93611 8.12 9.04167 8.96C8.4713 9.49333 8.08565 9.98 7.88472 10.42C7.6838 10.86 7.58333 11.3867 7.58333 12V16H6.41667ZM2.68333 4.88C2.63148 4.64 2.58935 4.29 2.55694 3.83C2.52454 3.37 2.5213 2.86 2.54722 2.3L0.816667 4.08L0 3.24L3.15 0L6.3 3.24L5.48333 4.08L3.73333 2.28C3.70741 2.78667 3.70093 3.23 3.71389 3.61C3.72685 3.99 3.75926 4.32 3.81111 4.6L2.68333 4.88ZM4.31667 8.3C4.0963 8.06 3.85324 7.74333 3.5875 7.35C3.32176 6.95667 3.11759 6.52667 2.975 6.06L4.12222 5.76C4.23889 6.09333 4.39444 6.41333 4.58889 6.72C4.78333 7.02667 4.96482 7.27333 5.13333 7.46L4.31667 8.3Z"
        fill={colour}
      />
    </svg>
  )
}
