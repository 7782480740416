import { DeployEnv } from "@geome/types"
import { ShellRetailAppConfig } from "../types"
import intlDataMessages from "./intlData/locales/default"

// I wonder where this can go?
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line prefer-type-alias/prefer-type-alias
    interface ProcessEnv {
      GOOGLE_API_KEY: string
      DEPLOY_ENV: DeployEnv
      NODE_ENV: string
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const configEnv: DeployEnv = ["prod", "uat", "test"].includes(process.env.DEPLOY_ENV || "")
  ? process.env.DEPLOY_ENV
  : "test"

const config: Partial<ShellRetailAppConfig> = {
  locale: "en_GB",
  remote: "/config/published/shellretaillocator2/{configEnv}/{locale}.json?cacheBust=1234",
  configEnv: configEnv,
  baseUrl: "/api/v2",
  intlData: {
    messages: intlDataMessages,
  },
  completions: {},
  resources: {
    locations: {
      enabled: true,
      views: ["within_bounds", "nearest_to", "along_corridor", "complete"],
    },
    on_street_charger_locations: {
      enabled: true,
      views: ["within_bounds", "along_corridor"],
    },
  },
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  map: {
    defaultTargetZoom: "14..18",
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
    },
  },
}

export { config }
