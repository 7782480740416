import React, { ReactElement, useId } from "react"

export const SmallDirectionsIcon = (): ReactElement => {
  const maskId = useId()
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      className="small-directions-icon"
      width="25"
      height="28"
      fill="none"
    >
      <rect
        width="23.8095"
        height="23.8095"
        x=".67993"
        y="2.42285"
        fill="var(--background-colour)"
        stroke="var(--foreground-colour)"
        rx="5"
      />
      <mask id={maskId} fill="var(--background-colour)">
        <path
          fillRule="evenodd"
          d="m14.1745 17.6348 5.4612-5.255-5.4612-5.25492v4.04122H7.5v9.7086h2.42716v-7.2815h4.24734v4.0416Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="var(--foreground-colour)"
        fillRule="evenodd"
        d="m14.1745 17.6348 5.4612-5.255-5.4612-5.25492v4.04122H7.5v9.7086h2.42716v-7.2815h4.24734v4.0416Z"
        clipRule="evenodd"
      />
      <path
        fill="var(--foreground-colour)"
        d="m19.6357 12.3798.6933.7206.7489-.7206-.7489-.7205-.6933.7205Zm-5.4612 5.255h-1v2.35l1.6934-1.6294-.6934-.7206Zm0-10.50992.6934-.72058-1.6934-1.62945v2.35003h1Zm0 4.04122v1h1v-1h-1Zm-6.6745 0v-1h-1v1h1Zm0 9.7086h-1v1h1v-1Zm2.42716 0v1h1.00004v-1H9.92716Zm0-7.2815v-1h-1v1h1Zm4.24734 0h1v-1h-1v1Zm4.7678-1.9339-5.4611 5.2549 1.3867 1.4412 5.4611-5.255-1.3867-1.4411Zm-5.4611-3.81385 5.4611 5.25495 1.3867-1.4411-5.4611-5.255-1.3867 1.44115Zm1.6933 3.32065V7.12488h-2v4.04122h2Zm-1-1H9.92716v2h4.24734v-2Zm-4.24734 0h-.60669v2h.60669v-2Zm-.60669 0H7.5v2h1.82047v-2Zm-2.82047 1v9.7086h2v-9.7086h-2Zm1 10.7086h2.42716v-2H7.5v2Zm3.4272-1v-7.2815H8.92716v7.2815h2.00004Zm-1.00004-6.2815h4.24734v-2H9.92716v2Zm5.24734 3.0416v-4.0416h-2v4.0416h2Z"
        mask={`url(#${maskId})`}
      />
    </svg>
  )
}
