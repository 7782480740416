import React, { ReactElement, useId } from "react"

export const PumpIcon = (): ReactElement => {
  const pumpAId = useId()
  const pumpBId = useId()
  const pumpCId = useId()
  const pumpDId = useId()
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="19"
      height="22"
    >
      <defs>
        <path id={pumpAId} d="M0 20h10V0H0z" />
        <path id={pumpCId} d="M0 4h6V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="currentColor" fillRule="nonzero" d="M0 20h12v1H0z" />
        <g strokeLinecap="square" transform="translate(1 1)">
          <mask id={pumpBId} fill="#fff">
            <use xlinkHref={`#${pumpAId}`} />
          </mask>
          <path stroke="currentColor" strokeWidth="2" d="M0 20h10V0H0z" mask={`url(#${pumpBId})`} />
        </g>
        <path stroke="currentColor" strokeLinecap="square" d="M16.117 15.164h1.954v-3.42h-1.954z" />
        <path
          stroke="currentColor"
          strokeLinecap="square"
          d="M16.117 12.233v-.977c0-1.374 2.247-3.418 2.247-3.418m-2.247 7.326v2.93c0 .959-.184 1.447-1.075 1.447-.892 0-1.344-.488-1.344-1.447V5.884c0-.687-.337-1.465-.976-1.465h-1.466"
        />
        <g strokeLinecap="square" transform="translate(3 3)">
          <mask id={pumpDId} fill="#fff">
            <use xlinkHref={`#${pumpCId}`} />
          </mask>
          <path stroke="currentColor" strokeWidth="2" d="M0 4h6V0H0z" mask={`url(#${pumpDId})`} />
        </g>
      </g>
    </svg>
  )
}
