import { DirectionsItinerary } from "@geome/react-components-next/lib/components/directionsItinerary"
import { Link } from "@geome/react-components-next/lib/components/link"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useRecoilCallback, useRecoilValue } from "@geome/recoil"
import {
  directionsGoogleURLSelector,
  directionsRouteSelector,
} from "@geome/recoil/dist/feature/directions/selectors"
import React, { ReactElement } from "react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { fullscreenModalAtom } from "../../recoil/atoms"
import { BasicPinIcon } from "../icons/basicPin"
import { ManeuverIcon } from "./maneuverIcon"

export const Itinerary = (): ReactElement | null => {
  const directionsRoute = useRecoilValue(directionsRouteSelector)
  const directionsURL = useRecoilValue(directionsGoogleURLSelector)
  const [publishSendToPhoneUserInteraction] = usePublishUserInteraction("send-to-phone", "click")
  const openSendToPhoneModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(fullscreenModalAtom, "send-to-phone")
        publishSendToPhoneUserInteraction()
      },
    [publishSendToPhoneUserInteraction]
  )
  const isMobile = useIsMobile()
  const { translate } = useI18n()

  if (!directionsRoute) return null
  return (
    <DirectionsItinerary
      route={directionsRoute}
      shouldRenderLegDestination
      renderTotals={({ content }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {content}
          {isMobile && directionsURL ? (
            <Link
              className="itinerary__navigate-button"
              href={directionsURL}
              name="navigate-link"
              target="_blank"
            >
              {translate("buttons.navigate")}
            </Link>
          ) : (
            <button className="itinerary__send-to-phone-button" onClick={openSendToPhoneModal}>
              {translate("buttons.send_to_phone")}
            </button>
          )}
        </div>
      )}
      renderStep={({ content, step }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexBasis: "30px" }}>
            <ManeuverIcon maneuver={step.maneuver} />
          </div>
          {content}
        </div>
      )}
      renderLeg={({ stepContent, leg }) => (
        <div className="itinerary__leg">
          {stepContent}
          <div className="itinerary__leg-destination">
            <BasicPinIcon />
            <span>
              {translate("directions_itinerary.leg_destination", { placeName: leg.end_address })}
            </span>
          </div>
        </div>
      )}
    />
  )
}
