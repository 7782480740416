import React, { ReactElement, Suspense, useEffect, useRef } from "react"
import useResizeObserver from "use-resize-observer"

import { Filters } from "../filters"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { LocationsList } from "../locationsList"
import { FiltersButton } from "../filters/button"
import { DirectionsSearch } from "../directionsSearch"
import { useRecoilValue } from "@geome/recoil"
import { appModeAtom } from "../../recoil/atoms"
import { Search } from "../search"
import { SidebarTabs } from "./tabs"
import { Itinerary } from "../itinerary"
import { useIsMobile } from "../../hooks/useIsMobile"
import { useViewSwitcher } from "./useViewSwitcher"
import { usePreviousValue } from "@geome/react-next/lib/hooks/usePreviousValue"
import { RouteOptions } from "../routeOptions"
import { RouteOptionsButton } from "../routeOptions/button"
import { useSidebarAdjustedUIPadding } from "../../hooks/useSidebarAdjustedUIPadding"

const InfoWindow = React.lazy(async () => ({
  default: (await import("../infoWindow")).InfoWindow,
}))

const AppSideBar = (): ReactElement => {
  const appMode = useRecoilValue(appModeAtom)
  const isMobile = useIsMobile()

  const {
    showList,
    showInfoWindow,
    showFilters,
    showRouteOptions,
    showItinerary,
    showCorridorList,
  } = useViewSwitcher()

  const previousShowFilters = usePreviousValue(showFilters)

  // Can replace `has-content` with :has(.app__sidebar-content:not(:empty) once that's a thing
  const className = useBEM({
    block: "app",
    element: "sidebar",
    modifiers: {
      mobile: isMobile,
      "has-content": showFilters || showInfoWindow || showList || showRouteOptions,
    },
  })

  // reset scroll on view changing
  const sidebarContentRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const filtersIsStillOpen = previousShowFilters === true && showFilters
    if (sidebarContentRef.current && !filtersIsStillOpen)
      sidebarContentRef.current.scrollTo({ top: 0 })
  }, [showList, showInfoWindow, showFilters, showItinerary, showCorridorList, previousShowFilters])

  useResizeObserver({
    ref: sidebarContentRef,
    onResize: (size) => {
      if (!sidebarContentRef.current) return
      sidebarContentRef.current.style.setProperty("--sidebar-width", `${size.width}px`)
    },
  })
  const sidebarRef = useRef<HTMLDivElement>(null)

  useSidebarAdjustedUIPadding({ sidebarRef, sidebarContentRef })

  return (
    <div className={className} ref={sidebarRef}>
      {appMode === "search" ? <Search /> : <DirectionsSearch />}

      <div className="app__sidebar-nav">
        <SidebarTabs />
        <div className="app__sidebar-nav-buttons">
          {appMode === "directions" && <RouteOptionsButton />}
          <FiltersButton />
        </div>
      </div>
      <div className="app__sidebar-content" ref={sidebarContentRef}>
        {showList && <LocationsList />}
        {showCorridorList && <LocationsList endpoint="along_corridor" />}
        {showItinerary && <Itinerary />}
        {showInfoWindow && (
          <Suspense fallback={<div className="info-window"></div>}>
            <InfoWindow />
          </Suspense>
        )}
        {showFilters && <Filters />}
        {showRouteOptions && <RouteOptions />}
      </div>
    </div>
  )
}
export { AppSideBar }
