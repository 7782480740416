import React, { ReactElement } from "react"
import { DirectionsArrowIcon } from "../icons/directionsArrow"

const ManeuverIcon = ({ maneuver }: { maneuver: string }): ReactElement | null => {
  if (!allPattern.test(maneuver)) return null

  return (
    <div className="maneuver-icon">
      <DirectionsArrowIcon
        straight={straightsPattern.test(maneuver)}
        left={leftsPattern.test(maneuver)}
        slight={slightsPattern.test(maneuver)}
      />
    </div>
  )
}

const allPattern =
  /^(|straight|(turn|keep|ramp|turn-slight|turn-sharp|turn-hard|fork)-(left|right))$/
const straightsPattern = /^(|straight)$/
const leftsPattern = /^(turn|keep|ramp|turn-slight|turn-sharp|fork)-left$/
const slightsPattern = /^(keep|ramp|turn-slight|fork)-(left|right)$/

export { ManeuverIcon }
