import { HTMLOverlay } from "@geome/react-components-next/lib/components/map/google/htmlOverlay"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import {
  useHighlightedLocation,
  useIsShowingMapClusters,
} from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { ShellRetailLocation } from "../../../types"
import { useI18n } from "@geome/react-next"

export const LocationTooltip = (): ReactElement | null => {
  const highlightedLocation = useHighlightedLocation<ShellRetailLocation>()
  const showingClusters = useIsShowingMapClusters({ resource: "locations" })
  const { translate } = useI18n()

  const className = useBEM({
    block: "location-tooltip",
    modifiers: {
      "is-selected": Boolean(highlightedLocation?.isSelected),
    },
  })

  if (!highlightedLocation || showingClusters) return null

  return (
    <HTMLOverlay lat={highlightedLocation.lat} lng={highlightedLocation.lng}>
      <div className={className}>
        <div className="location-tooltip__name">{highlightedLocation.name}</div>
        <div className="location-tooltip__address">{highlightedLocation.formatted_address}</div>
        {highlightedLocation.inactive && (
          <div className="location-tooltip__closed">
            {translate("locations.closed_temporarily")}
          </div>
        )}
      </div>
    </HTMLOverlay>
  )
}
