import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React, { ReactElement } from "react"

export const DirectionsIcon = ({ scale = 1 }: { scale?: number }): ReactElement => {
  const colour = useCSSCustomProperty("--shell-grey-colour", "#404040")

  return (
    <svg
      aria-hidden="true"
      fill="none"
      className="directions-icon"
      width={42 * scale}
      height={41 * scale}
      viewBox="0 0 42 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="directions-icon__background"
        height="40"
        rx={5 / scale}
        stroke={colour}
        width="40"
        x="1.14209"
        y=".5"
      />
      <path
        className="directions-icon__arrow"
        clipRule="evenodd"
        d="m22.1399 26.3205 9-8.6602-9-8.6603v6.6603h-7-1-3v16h4v-12h7z"
        fill={colour}
        fillRule="evenodd"
      />
    </svg>
  )
}
