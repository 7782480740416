import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React from "react"

export const BasicPinIcon = (): JSX.Element => {
  const fill = useCSSCustomProperty("--tertiary-colour", "black")

  return (
    <svg
      aria-hidden="true"
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0Z"
        fill={fill}
      />
    </svg>
  )
}
