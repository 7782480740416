import { TabButton } from "@geome/react-components-next/lib/components/buttons/tab"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { useMatchMedia } from "@geome/react-next/lib/hooks/useMatchMedia"
import { useRecoilState, useRecoilValue } from "@geome/recoil"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { useIsShowingMapClusters } from "@geome/recoil/dist/feature/locations/hooks"
import { useMobileMapListState } from "@geome/recoil/dist/feature/ui/hooks"
import React, { ReactElement } from "react"
import { appModeAtom, directionsViewAtom } from "../../recoil/atoms"
import { ListIcon } from "../icons/list"
import { MapIcon } from "../icons/map"
import { RouteIcon } from "../icons/route"

export const SidebarTabs = (): ReactElement | null => {
  const isMobile = useMatchMedia("(max-width: 767px)")
  const appMode = useRecoilValue(appModeAtom)
  const [directionsView, setDirectionsView] = useRecoilState(directionsViewAtom)
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })
  const directionsRoute = useRecoilValue(directionsRouteSelector)
  const { mobileView, setMobileView } = useMobileMapListState()

  return (
    <CSSCustomPropertyContextProvider
      properties={{
        "--primary-colour": "#DD1D21",
      }}
    >
      {appMode === "search" && isMobile && !isShowingClusters && (
        <>
          <TabButton
            name="map"
            renderIcon={() => <MapIcon />}
            active={mobileView === "map"}
            configPath="mobile_nav."
            onClick={() => setMobileView("map")}
          />
          <TabButton
            name="list"
            renderIcon={() => <ListIcon />}
            active={mobileView === "list"}
            configPath="mobile_nav."
            onClick={() => setMobileView("list")}
          />
        </>
      )}
      {appMode === "directions" && directionsRoute && (
        <>
          {isMobile && (
            <TabButton
              name="map"
              renderIcon={() => <MapIcon />}
              active={directionsView === "map"}
              configPath="directions_nav."
              onClick={() => setDirectionsView("map")}
            />
          )}
          <TabButton
            name="list"
            renderIcon={() => <ListIcon />}
            active={directionsView === "list"}
            configPath="mobile_nav."
            onClick={() => setDirectionsView("list")}
          />
          <TabButton
            name="itinerary"
            renderIcon={() => <RouteIcon />}
            active={directionsView === "itinerary"}
            configPath="directions_nav."
            onClick={() => setDirectionsView("itinerary")}
          />
        </>
      )}
    </CSSCustomPropertyContextProvider>
  )
}
