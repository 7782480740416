import React, { ReactElement, useCallback } from "react"
import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { ScrollWithHeaderFooter } from "@geome/react-components-next/lib/components/scrollWithHeaderFooter"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { CheckboxControl } from "@geome/react-components-next/lib/components/checkboxControl"
import { RangeInput } from "@geome/react-components-next/lib/components/rangeInput"
import { CloseIcon } from "../icons/close"
import {
  ToggleableDirectionsOptions,
  useToggleableDirectionsOptions,
} from "./useToggleableDirectionsOptions"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { TruckIcon } from "../icons/truckIcon"
import { useRadiusDirectionsOption } from "./useRadiusDirectionsOption"
import { useConfig } from "@geome/react-next/lib/hooks/config"
import { DistanceUnit } from "@geome/intl"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

export const RouteOptions = (): ReactElement => {
  const { toggleableOptions, toggleOption } = useToggleableDirectionsOptions()
  const { radius, setRadius, options } = useRadiusDirectionsOption()

  const { setCurrentModal } = useModalState()
  const { translate } = useI18n()

  const closeRouteOptions = useCallback(async () => setCurrentModal(null), [setCurrentModal])

  useSubscribeToUserInteraction("*,-route-options:*:*,-*:toggle-modal-button:*", closeRouteOptions)

  const unit = useConfig<DistanceUnit>("intlData.formats.number.distance.unit", true)

  return (
    <BlockScopingContextProvider block="route-options">
      <ScrollWithHeaderFooter
        className="route-options"
        renderHeader={() => (
          <div className="route-options__title-bar">
            <h3>{translate("route_options.title")}</h3>
            <ButtonWithIcon
              className="route-options__close-button"
              text={translate("route_options.close") as string}
              textPosition="tooltip"
              icon={<CloseIcon />}
              onClick={closeRouteOptions}
              name="close-button"
            />
          </div>
        )}
      >
        {Object.entries(toggleableOptions).map(
          ([key, checked]: [keyof ToggleableDirectionsOptions, boolean]) => (
            <CheckboxControl
              key={key}
              id={key}
              className="route-options__option"
              style="button"
              checked={checked}
              onCheck={() => toggleOption(key)}
            >
              {translate(`route_options.options.${key}`)}
            </CheckboxControl>
          )
        )}

        <h3 className="route-options__group-heading">
          <TruckIcon />
          {translate("route_options.radius.heading")}
        </h3>
        <div className="route-options__radius-input">
          <RangeInput
            value={radius}
            min={options.range?.min ?? 1}
            max={options.range?.max ?? 25}
            step={options.steps}
            onChange={setRadius}
            valueTranslationToken="route_options.radius.value"
            labelTranslationToken="route_options.radius.label"
            unit={unit}
            delay={250}
          />
        </div>
      </ScrollWithHeaderFooter>
    </BlockScopingContextProvider>
  )
}
