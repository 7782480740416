import { IconProps } from "@geome/react-components-next/lib/components/icons/types"
import React, { ReactElement } from "react"

export type DirectionsArrowIconProps = {
  straight?: boolean
  left?: boolean
  slight?: boolean
} & IconProps

export const DirectionsArrowIcon = ({
  straight,
  left,
  slight,
}: DirectionsArrowIconProps): ReactElement => {
  const className = `directions-arrow ${left ? "directions-arrow--left" : ""}`

  if (straight) {
    return (
      <svg
        aria-hidden="true"
        width="12"
        height="24"
        viewBox="0 0 12 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M5.0625 23.25V4.3125L2.25 7.125L0.9375 5.8125L6 0.75L11.0625 5.8125L9.75 7.125L6.9375 4.3125V23.25H5.0625Z"
          fill="black"
        />
      </svg>
    )
  }

  if (slight) {
    return (
      <svg
        aria-hidden="true"
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M0.370117 20V10.6875C0.370117 10.4375 0.422201 10.1979 0.526367 9.96875C0.630534 9.73958 0.76595 9.54167 0.932617 9.375L8.43262 1.875H4.46387V0H11.6201V7.15625H9.74512V3.1875L2.24512 10.6875V20H0.370117Z"
          fill="black"
        />
      </svg>
    )
  }

  return (
    <svg
      aria-hidden="true"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.25 17.75V6.25C0.25 5.72917 0.432292 5.28646 0.796875 4.92188C1.16146 4.55729 1.60417 4.375 2.125 4.375H14.1875L11.375 1.5625L12.6875 0.25L17.75 5.3125L12.6875 10.375L11.375 9.0625L14.1875 6.25H2.125V17.75H0.25Z"
        fill="black"
      />
    </svg>
  )
}
