import React, { ReactElement } from "react"
import { OptionsSetter } from "@geome/react-components-next/lib/components/map/google/optionsSetter"
import { useIsMobile } from "../../hooks/useIsMobile"

export const MapOptions = (): ReactElement => {
  const isMobile = useIsMobile()
  const mapTypeControl = false
  const zoomControl = isMobile ? false : true

  return <OptionsSetter mapTypeControl={false} zoomControl={zoomControl} />
}
