import { ShellRetailLocation } from "../../types"
import { useSelectedGooglePlace } from "@geome/recoil/dist/feature/places/hooks"
import { useAPIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import {
  useSubscribeAndWaitForChange,
  useSubscribeToUserInteraction,
} from "@geome/react-next/lib/hooks/userInteraction"
import {
  onCompletionSelect,
  onDirectionsCompletionSelect,
  onFilterToggle,
  onGeolocationClick,
  sendLocationSelect,
} from "./callbacks"
import { sendAnalytics } from "./sendAnalytics"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { useRecoilValue } from "@geome/recoil"
import { waypointSelectionIntentSelector } from "@geome/recoil/dist/feature/directions/selectors"

export const Analytics = (): null => {
  const selectedPlace = useSelectedGooglePlace()
  const selectedLocation = useSelectedLocation<ShellRetailLocation>()
  const selectedGooglePlace = useSelectedGooglePlace()
  const waypointSelectionIntent = useRecoilValue(waypointSelectionIntentSelector)
  const filters = useAPIFilters()

  useSubscribeToUserInteraction("filters:filterGroups:toggle", onFilterToggle)

  useSubscribeToUserInteraction(
    "*:telephone-link:click",
    (_topic, { location }: { href: string; location: ShellRetailLocation }) =>
      sendAnalytics({
        action: "phoneclick",
        text: JSON.stringify({ id: location.id, name: location.name }),
      })
  )

  useSubscribeToUserInteraction(
    "*:website-link:click",
    (_topic, { location }: { href: string; location: ShellRetailLocation }) =>
      sendAnalytics({
        action: "websiteclick",
        text: JSON.stringify({ id: location.id, name: location.name }),
      })
  )

  useSubscribeToUserInteraction(
    "*:directions-link:click",
    (topic, { location }: { href: string; location: ShellRetailLocation }) => {
      const [block] = topic.split(":")
      sendAnalytics({
        action: block === "locations-list" ? "directionsonresults" : "directionsoncard",
        text: { id: location.id, name: location.name },
      })
    }
  )

  useSubscribeToUserInteraction(
    "*:location:select",
    (_topic, { location }: { location: ShellRetailLocation }): void => sendLocationSelect(location)
  )

  useSubscribeToUserInteraction("*:send-to-phone:click", (_topic): void =>
    sendAnalytics({
      action: "sendtophone",
    })
  )

  useSubscribeToUserInteraction("*:navigate-link:click", (_topic): void =>
    sendAnalytics({
      action: "navigate",
    })
  )

  useSubscribeAndWaitForChange("search:completion:select", onCompletionSelect, {
    selectedLocation,
    selectedPlace,
    filters,
  })

  useSubscribeAndWaitForChange(
    "directions-search:completion:select",
    onDirectionsCompletionSelect,
    {
      selectionIntents: waypointSelectionIntent,
    }
  )

  useSubscribeAndWaitForChange("*:geolocation-button:click", onGeolocationClick, {
    selectedGooglePlace,
  })

  return null
}
