import { useConfig } from "@geome/react-next"
import { DeployEnv } from "@geome/types"
import React, { ReactElement, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

const MOUSEFLOW_IDS: Record<DeployEnv, string> = {
  prod: "0952ff75-b6db-4f44-8a60-e7f92892b8c6",
  uat: "344505b0-fe49-4ea5-b3a5-62f614597f01",
  dev: "dev-geo-2",
  test: "test-geo-2",
}

type PermissionsPostMessage = {
  consent?: string
  id: string
  index: number
  locale: string
}

type PermissionsPostMessageInner = {
  date: string
  statistics: boolean
  marketing: boolean
  preferences: boolean
  functional: boolean
}

export const MouseFlow = (): ReactElement | null => {
  const mouseFlowEnabled = useConfig<boolean>("mouseflow", false)
  const [mouseFlowActive, setMouseFlowActive] = useState<boolean>(false)

  useEffect(() => {
    if (!mouseFlowEnabled) return

    const handlePermissionsPostMessage = (event: MessageEvent): void => {
      const data = event.data as PermissionsPostMessage

      if (!data.consent) return

      const consent = JSON.parse(data.consent) as PermissionsPostMessageInner
      setMouseFlowActive(consent.statistics)
    }

    window.addEventListener("message", handlePermissionsPostMessage)

    return () => {
      window.removeEventListener("message", handlePermissionsPostMessage)
    }
  }, [mouseFlowEnabled])

  if (mouseFlowActive) {
    return (
      <Helmet>
        <script type="text/javascript">
          {`
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/${MOUSEFLOW_IDS[process.env.DEPLOY_ENV]}.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();`}
        </script>
      </Helmet>
    )
  }

  return null
}
