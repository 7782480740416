import React, { ReactElement } from "react"

import { useDurationParams } from "@geome/react-components-next/lib/components/directionsItinerary/hooks/useDurationParams"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import { directionsRouteStatsSelector } from "@geome/recoil/dist/feature/directions/selectors"

export const RouteSummary = (): ReactElement | null => {
  const { translate } = useI18n()
  const routeStats = useRecoilValue(directionsRouteStatsSelector)
  const durationParams = useDurationParams(routeStats?.duration)

  if (!routeStats) return null

  return (
    <div className="route-summary">
      <div>
        <span className="route-summary--label">
          {translate("directions_itinerary.totals.summary_title")}
        </span>
        {translate("directions_itinerary.totals.duration", durationParams)}{" "}
        {translate("directions_itinerary.totals.distance", {
          distance: routeStats.distance / 1000,
          convert: true,
        })}
      </div>
    </div>
  )
}
