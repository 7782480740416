import { useRecoilState } from "@geome/recoil"
import React, { ReactElement, Suspense, useCallback } from "react"
import { fullscreenModalAtom } from "../../recoil/atoms"
import { FullscreenModals } from "../../types"

type FullScreenModalsProps = {
  modals: { [k in FullscreenModals]: (closeModal: () => void) => ReactElement }
}

export const FullScreenModals = ({ modals }: FullScreenModalsProps): ReactElement | null => {
  const [currentModal, setCurrentModal] = useRecoilState(fullscreenModalAtom)
  const closeModal = useCallback(() => setCurrentModal(null), [setCurrentModal])
  if (!currentModal) return null

  const modalRenderProp = modals[currentModal]
  return (
    <div className="full-screen-modal">
      <div className="full-screen-modal__backdrop" onClick={closeModal}></div>
      <Suspense fallback={null}>{modalRenderProp(closeModal)}</Suspense>
    </div>
  )
}
