import React from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Resources } from "../resources"
import { I18nContextProvider } from "@geome/react-next/lib/context/i18nContext/provider"
import { AppSideBar } from "./sidebar"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseDirectionsOptions } from "@geome/recoil/dist/feature/directions/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { AppConfig } from "@geome/types"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import DistanceFormatHelper from "@geome/intl/lib/formatHelper/distance"
import { Map } from "../map"
import { UserInteractionContextProvider } from "@geome/react-next/lib/context/userInteraction"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { FullScreenModals } from "../fullScreenModals"
import { setSelectionIntentFromURL } from "@geome/recoil/dist/feature/selection/initalisers/fromURL"
import { ShellRetailAppConfig } from "../../types"
import { Analytics } from "../analytics"
import { MouseFlow } from "../mouseflow"
import { HelmetProvider } from "react-helmet-async"

const SendToPhoneModal = React.lazy(async () => ({
  default: (await import("../fullScreenModals/sendToPhone")).SendToPhoneModal,
}))

const App = (): JSX.Element => (
  <HelmetProvider>
    <ConfigContextProvider value={config as ShellRetailAppConfig}>
      {(finalConfig: AppConfig) => (
        <RecoilMultiInitRoot
          initialisers={[
            initialiseFilterState(finalConfig),
            initialiseResourcesState(finalConfig),
            initialiseDirectionsOptions(finalConfig),
            setSelectionIntentFromURL,
          ]}
        >
          <UserInteractionContextProvider>
            <GeospatialApiRequesterContextProvider>
              <I18nContextProvider
                intlData={finalConfig.intlData}
                locale={finalConfig.locale}
                intlExplorer={finalConfig.intlExplorer}
                mode={process.env.NODE_ENV !== "prod" ? "development" : "production"}
                formatHelpers={[DistanceFormatHelper]}
              >
                <GoogleMapsLoaderUsingConfig>
                  <CSSCustomPropertyContextProvider
                    properties={{
                      "--primary-colour": "#DD1D21",
                      "--shell-yellow-colour": "#FBCE07",
                      "--shell-grey-colour": "#404040",
                      "--shell-light-grey-colour": "#F7F7F7",
                      "--shell-dark-red-colour": "#b1171a",
                      "--tertiary-colour": "var(--shell-grey-colour)",
                      "--search-input__text-colour": "var(--shell-grey-colour)",
                      "--search-input__margin": "15px",
                      "--tab-text-colour": "var(--shell-grey-colour)",
                      "--text-dark-colour": "var(--shell-grey-colour)",
                    }}
                  >
                    <BlockScopingContextProvider block="app">
                      <div className="app">
                        <FullScreenModals
                          modals={{
                            "send-to-phone": (closeModal) => (
                              <SendToPhoneModal onClose={closeModal} />
                            ),
                          }}
                        />
                        <AppSideBar />
                        <Map />
                        <Resources />
                        <Analytics />
                        <MouseFlow />
                      </div>
                    </BlockScopingContextProvider>
                  </CSSCustomPropertyContextProvider>
                </GoogleMapsLoaderUsingConfig>
              </I18nContextProvider>
            </GeospatialApiRequesterContextProvider>
          </UserInteractionContextProvider>
        </RecoilMultiInitRoot>
      )}
    </ConfigContextProvider>
  </HelmetProvider>
)

export { App }
