import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { ShellRetailMarker } from "@geome/markers"
import { ShellRetailLocation } from "../../types"
import { useRecoilValue } from "@geome/recoil"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"

export const Locations = (): ReactElement => {
  const route = useRecoilValue(directionsRouteSelector)

  const locations = useLocations<ShellRetailLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })

  const alongCorridorLocations = useLocations<ShellRetailLocation>({
    resource: "locations",
    endpoint: "along_corridor",
  })

  const onStreetChargerLocations = useLocations<ShellRetailLocation>({
    resource: "on_street_charger_locations",
    endpoint: "within_bounds",
  })

  const onStreetChargerCorridorLocations = useLocations<ShellRetailLocation>({
    resource: "on_street_charger_locations",
    endpoint: "along_corridor",
  })

  return (
    <>
      {(route ? alongCorridorLocations : locations).map((location) => (
        <ShellRetailMarker key={location.id} location={location} />
      ))}
      {(route ? onStreetChargerCorridorLocations : onStreetChargerLocations).map((location) => (
        <ShellRetailMarker
          key={location.id}
          location={location}
          resource="on_street_charger_locations"
        />
      ))}
    </>
  )
}
