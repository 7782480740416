import { GoogleMap } from "@geome/react-components-next/lib/components/map/google"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { CustomControl } from "@geome/react-components-next/lib/components/map/google/customControl"

import React, { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { DirectionsRoute } from "./directionsRoute"
import { Locations } from "./locations"
import { LocationTooltip } from "./locationTooltip"
import { MapOptions } from "./mapOptions"
import { RouteSummary } from "../routeSummary"
import { DotClusters } from "./dotClusters"
import { GoogleMapContext } from "@geome/react-components-next/lib/components/map/google/contexts/map"
import { Clusters } from "./clusters"
import { DeployEnv } from "@geome/types"

export const Map = (): ReactElement => (
  <BlockScopingContextProvider block="map">
    <GoogleMap>
      <DirectionsRoute />
      <Locations />
      <LocationTooltip />
      <DeploymentSwitch onlyIn={["dev", "test"]}>
        <ZoomLevelSwitch switchZoom={8}>
          <Clusters />
          <DotClusters />
        </ZoomLevelSwitch>
        <Clusters />
      </DeploymentSwitch>

      <MapOptions />

      <CustomControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
        <RouteSummary />
      </CustomControl>
    </GoogleMap>
  </BlockScopingContextProvider>
)

type ZoomLevelSwitchProps = {
  switchZoom: number
  children: [ReactNode, ReactNode]
}

const ZoomLevelSwitch = ({ switchZoom, children }: ZoomLevelSwitchProps): ReactElement => {
  const mapRef = useContext(GoogleMapContext)
  const [zoomLevel, setZoomLevel] = useState(20)

  useEffect(() => {
    if (!mapRef) return
    setZoomLevel(mapRef.getZoom() || 20)

    const listener = mapRef.addListener("zoom_changed", () => setZoomLevel(mapRef.getZoom() || 20))
    return () => listener.remove()
  }, [mapRef])

  if (zoomLevel >= switchZoom) return <>{children[0]}</>
  return <>{children[1]}</>
}

type DeploymentSwitchProps = {
  onlyIn: DeployEnv[]
  children: [ReactNode, ReactNode]
}

const DeploymentSwitch = ({ onlyIn, children }: DeploymentSwitchProps): ReactElement => {
  const shouldRenderFirstChild = useMemo(() => onlyIn.includes(process.env.DEPLOY_ENV), [onlyIn])

  if (shouldRenderFirstChild) return <>{children[0]}</>
  return <>{children[1]}</>
}
