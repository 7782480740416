import React, { ReactElement, useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "@geome/recoil"
import { targetBoundsAtom } from "@geome/recoil/dist/feature/map/atoms"
import { useClusters } from "@geome/recoil/dist/feature/locations/hooks"
import { HTMLOverlay } from "@geome/react-components-next/lib/components/map/google/htmlOverlay"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { scaleLog } from "d3-scale"

export const DotClusters = (): ReactElement => {
  const setTarget = useSetRecoilState(targetBoundsAtom)

  const route = useRecoilValue(directionsRouteSelector)
  const clusters = useClusters({ resource: "locations", endpoint: "within_bounds" })
  const corridorClusters = useClusters({ resource: "locations", endpoint: "along_corridor" })

  const clusterScale = useMemo(() => {
    const clusterMin = Math.min(...clusters.map((c) => c.size))
    const clusterMax = Math.max(...clusters.map((c) => c.size))
    const scale = scaleLog([clusterMin, clusterMax], [8, 24]).clamp(true)
    return scale
  }, [clusters])

  return (
    <>
      {(route ? corridorClusters : clusters).map(
        ({
          id,
          size,
          bounds: {
            ne: [north, east],
            sw: [south, west],
          },
          centroid: [lat, lng],
        }) => (
          <HTMLOverlay key={id} lat={lat} lng={lng}>
            <div
              className="dot-cluster"
              role="button"
              tabIndex={0}
              style={{
                inlineSize: clusterScale(size),
                blockSize: clusterScale(size),
              }}
              onKeyUp={() => {}}
              onClick={() =>
                setTarget({
                  north,
                  east,
                  south,
                  west,
                })
              }
            ></div>
          </HTMLOverlay>
        )
      )}
    </>
  )
}
