import React, { ReactElement } from "react"
import { DirectionsRenderer } from "@geome/react-components-next/lib/components/map/google/directionsRenderer"
import { useRecoilValue } from "@geome/recoil"
import { directionsResultAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { appModeAtom } from "../../recoil/atoms"

export const DirectionsRoute = (): ReactElement | null => {
  const directions = useRecoilValue(directionsResultAtom)
  const appMode = useRecoilValue(appModeAtom)
  if (appMode !== "directions" || directions === null) return null

  return <DirectionsRenderer directions={directions} />
}
