import { atom } from "@geome/recoil"
import { AppModes, FullscreenModals } from "../../types"

export const appModeAtom = atom<AppModes>({ key: "appModeAtom", default: "search" })

export const directionsViewAtom = atom<"map" | "list" | "itinerary">({
  key: "directionsViewAtom",
  default: "map",
})

export const fullscreenModalAtom = atom<FullscreenModals | null>({
  key: "fullscreenModalAtom",
  default: null,
})
